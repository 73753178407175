<template>
	<div class="wrap__content">
		<div class="section__banner">
			<div class="container">
				<div class="banner__container">
					<div class="banner__container--info">
						<h1 class="banner__title">
							{{ $t('site.home.banner.title') }}
						</h1>
						<div class="banner__desc" v-html="$t('site.home.banner.desc')"></div>
						<div class="banner__link">
							<router-link :to="'/' + $i18n.locale + '/login'" class="link--btn link--btn-shadow"
								>{{ $t('site.home.banner.link') }}
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.667 17.5v-1.667a3.333 3.333 0 00-3.334-3.333H6.667a3.333 3.333 0 00-3.334 3.333V17.5M10 9.167A3.333 3.333 0 1010 2.5a3.333 3.333 0 000 6.667z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section__block section__block--about" id="about">
			<div class="container">
				<div class="section__title">
					{{ $t('site.home.about.title') }}
				</div>
				<div class="about__block" v-html="$t('site.home.about.desc')"></div>
				<div class="about__link">
					<a :href="'/pdf/instructions-' + $i18n.locale + '.pdf'" target="_blank" class="link--btn"
						><span>{{ $t('button.download_instruction') }}</span>
						<img src="../assets/img/icon-instruction-w.svg"
					/></a>
				</div>
			</div>
		</div>

		<div class="section__block section__block--number">
			<div class="container">
				<div class="number__row">
					<div class="number__item--row">
						<div class="number__item--col">
							<div class="number__item">
								<div class="number__item--block">
									<div class="number__item--top">
										<div class="number__item--number">
											<!-- <span>937</span> -->

											<number
												tag="span"
												:from="0"
												:to="937"
												:duration="3"
												:delay="1"
												easing="Power1.easeOut"
											/>
										</div>
										<div class="number__item--icon">
											<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M24 40V20M36 40V8M12 40v-8" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
									</div>
									<div class="number__item--text">
										{{ $t('site.home.number.number1') }}
									</div>
								</div>
							</div>
						</div>
						<div class="number__item--col">
							<div class="number__item number__item--active">
								<div class="number__item--block">
									<div class="number__item--top">
										<div class="number__item--number">
											<p style="white-space: nowrap">
												<span>+</span>
												<number
													tag="span"
													:from="0"
													:to="47"
													:duration="3"
													:delay="1"
													easing="Power1.easeOut"
												/>
											</p>
										</div>
										<div class="number__item--icon">
											<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.6 26.4c-2.272 0-4.112-.832-5.52-2.496C6.704 22.208 6.016 20 6.016 17.28c0-2.72.688-4.912 2.064-6.576C9.488 9.008 11.328 8.16 13.6 8.16c2.24 0 4.064.832 5.472 2.496 1.408 1.664 2.112 3.872 2.112 6.624s-.704 4.96-2.112 6.624C17.664 25.568 15.84 26.4 13.6 26.4zm20.352-18h2.832L13.84 42h-2.832L33.952 8.4zM13.6 24.24c1.568 0 2.816-.608 3.744-1.824.96-1.248 1.44-2.96 1.44-5.136s-.48-3.872-1.44-5.088c-.928-1.248-2.176-1.872-3.744-1.872-1.6 0-2.864.624-3.792 1.872-.928 1.248-1.392 2.944-1.392 5.088s.464 3.84 1.392 5.088C10.736 23.616 12 24.24 13.6 24.24zm20.592 18c-2.24 0-4.064-.832-5.472-2.496-1.408-1.696-2.112-3.904-2.112-6.624 0-2.72.704-4.912 2.112-6.576C30.128 24.848 31.952 24 34.192 24c2.272 0 4.096.848 5.472 2.544 1.408 1.664 2.112 3.856 2.112 6.576 0 2.72-.704 4.928-2.112 6.624-1.376 1.664-3.2 2.496-5.472 2.496zm0-2.16c1.6 0 2.864-.624 3.792-1.872.928-1.248 1.392-2.944 1.392-5.088s-.464-3.84-1.392-5.088c-.928-1.248-2.192-1.872-3.792-1.872-1.568 0-2.832.624-3.792 1.872-.928 1.216-1.392 2.912-1.392 5.088s.464 3.888 1.392 5.136c.96 1.216 2.224 1.824 3.792 1.824z" fill="#52A5FC"/></svg>
										</div>
									</div>
									<div class="number__item--text">
										{{ $t('site.home.number.number2') }}
									</div>
								</div>
							</div>
						</div>
						<div class="number__item--col">
							<div class="number__item">
								<div class="number__item--block">
									<div class="number__item--top">
										<div class="number__item--number">
											<!-- <span>695</span> -->
											<number
												tag="span"
												:from="0"
												:to="695"
												:duration="3"
												:delay="1"
												easing="Power1.easeOut"
											/>
										</div>
										<div class="number__item--icon">
											<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M42 24A18 18 0 1129.562 6.88" stroke="#52A5FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
									</div>
									<div class="number__item--text">
										{{ $t('site.home.number.number3') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section__block--map" id="map">
			<div class="container">
				<!-- <div class="map__container"> -->
				<v-kazmap></v-kazmap>

				<!-- </div> -->
			</div>
		</div>

		<div class="section__block section__block--news" id="news" v-if="news.length > 0">
			<div class="container">
				<div class="section__title">
					{{ $t('site.home.news.title') }}
				</div>
				<div class="news__sliders--block">
					<div class="news__item pointer" v-for="(item, index) in news" :key="index">
						<div class="news__item--img" v-if="item.images.length > 0">
							<img :src="urlApi + item.images[0].path" />
						</div>
						<div class="news__item--info">
							<div class="news__item--date">
								{{ item.created_at | formatOnlyDate }}
							</div>
							<div class="news__item--title">
								{{ item['title' + getSuffix] }}
							</div>
							<div class="news__item--link">
								<a>
									<img src="../assets/img/icon-arrow-right-b.svg" />
								</a>
							</div>
							<router-link :to="'/' + $i18n.locale + '/description/' + item.id" class="news__link">
								{{ $t('site.home.news.more') }}
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<div class="news__sliders--bottom" v-if="news">
				<!-- <div class="item__row item__ac pagination">
                <div class="pagination__first  pointer" v-bind:class="{'pagination__active':paginationIndex==0}" @click="changePagination(0)"></div>
                <div class="pagination__second pointer" v-bind:class="{'pagination__active':paginationIndex==1}"  @click="changePagination(1)"></div>
        </div> -->
				<div class="news__all" @click="$router.push('/' + $i18n.locale + '/news')">
					<a class="news__all--link">{{ $t('site.home.news.all_news') }}</a>
				</div>
			</div>
		</div>

		<div class="section__block section__block--video" id="video">
			<div class="container">
				<div class="section__block--iframe">
					<iframe
						width="100%"
						height="500"
						src="https://www.youtube.com/embed/_ozSK9XJHi4?si=8RDnKZyywJC5SEke"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
				<div class="section__block--iframe section__block--iframe--second">
					<iframe
						width="100%"
						height="500"
						src="https://www.youtube.com/embed/0-j-s7gjTFI?si=SYN5XzZLkqq6B8Nl"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin"
						allowfullscreen
					></iframe>
				</div>
			</div>
		</div>

		<div class="section__block section__block--faq" id="faq">
			<div class="container">
				<div class="section__title">
					{{ $t('site.home.faq.title') }}
				</div>

				<div class="faq__row">
					<div
						class="faq__item"
						v-for="(item, index) in $t('site.faqList')"
						:key="index"
						:class="{ 'faq__item--active': faqActive == index }"
					>
						<div class="faq__item--top" @click="faqClick(index)">
							<div class="faq__item--title">
								{{ item.title }}
							</div>
							<div class="faq__item--arrow">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 9l6 6 6-6" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</div>
						</div>
						<transition name="slide">
							<div class="faq__item--body" v-if="faqActive == index">
								<hr />
								<div class="faq__item--text" v-html="item.desc"></div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'
import '../assets/css/swiper.min.css'
import { directive } from 'vue-awesome-swiper'

const SUFFIXES = {
	kz: '_kz',
	ru: '',
}

export default {
	directives: {
		swiper: directive,
	},
	data() {
		return {
			paginationIndex: 0,
			news: [],
			urlApi: urlApi,
			faqActive: 0,
			menuClick: false,
			videoOption: {
				slidesPerView: 3,
				spaceBetween: 0,
				centeredSlides: true,
				// loop: true,
				/*autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },*/
				pagination: {
					el: '.video__sliders--pagination',
					clickable: true,
				},
				breakpoints: {
					320: {
						slidesPerView: 1,
						spaceBetween: 15,
					},
					520: {
						slidesPerView: 1,
						spaceBetween: 15,
					},
					992: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 2,
					},
					1200: {
						slidesPerView: 3,
					},
				},
			},
		}
	},
	computed: {
		getSuffix() {
			return SUFFIXES[this.$i18n.locale]
		},
	},
	mounted() {
		this.getNews()
	},
	methods: {
		changePagination(index) {
			this.paginationIndex = index

			if (index == 1) {
				document.getElementsByClassName('news__sliders--block')[0].scrollLeft =
					document.getElementsByClassName('news__sliders--block')[0].scrollLeft + 800
			} else {
				document.getElementsByClassName('news__sliders--block')[0].scrollLeft =
					document.getElementsByClassName('news__sliders--block')[0].scrollLeft - 800
			}
		},
		changedSlide(id) {
			this.$router.push('/' + this.$i18n.locale + '/description/' + id)
		},
		getNews() {
			api.get('guest/news', {}).then((response) => {
				this.news = response.data.data?.slice(0, 3)
			})
		},
		faqClick: function (index) {
			if (this.faqActive == index) this.faqActive = null
			else this.faqActive = index
		},
	},
	head: {
		title() {
			return {
				inner: this.$t('site.home.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.recovery.description') }]
		},
	},
}
</script>

<style></style>
